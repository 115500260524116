import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

const Footer = () => {

  const [company_details, setCompanyDatas] = useState([]);

    useEffect(() => {
        fetchCompanyDatas();
    }, []);

    const fetchCompanyDatas = async () => {
      try {
        const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/company-details');
        setCompanyDatas(response.data);
      } catch (error) {
        console.error('Error fetching company details: ', error);
      }
    };

  return (
    <div>
      <footer id="footer" style={{ backgroundColor: '#198754', borderRadius: '50px 50px 0 0'}} >


        <div className="footer-top" style={{ backgroundColor: '#000000', borderRadius: '50px 50px 0 0', padding: '50px 0' }}>
          <div className="container">
            <div className="row">

              {company_details.map(company_detail => (
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>{company_detail.c_name}</h3>
                  <p>{company_detail.c_address} <br /><br />
                    <strong>Phone:</strong> {company_detail.c_phone}<br />
                    <strong>Email:</strong> {company_detail.c_email}<br />
                  </p>
                </div>
              ))}

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bx bx-chevron-right"></i> <Link to="about" className="nav-link scrollto">This is Fancy Group</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to="brands" className="nav-link scrollto">Brands</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to="services" className="nav-link scrollto">Services</Link></li>
                  {/* <li><i className="bx bx-chevron-right"></i> <Link to="team" className="nav-link scrollto">Team</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to="contact" className="nav-link scrollto">Contact</Link></li> */}
                  <li><i className="bx bx-chevron-right"></i> <Link to="/" className="nav-link scrollto">Terms of service</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to="/" className="nav-link scrollto">Privacy policy</Link></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Companies</h4>
                <ul>
                  <li><i className="bx bx-chevron-right"></i><a href="https://fancygroupltd.com/" target="_blank">Fancy Beauty Concept</a></li>
                  <li><i className="bx bx-chevron-right"></i><a href="https://planethackltd.com/" target="_blank">Fancy IT</a></li>
                  <li><i className="bx bx-chevron-right"></i><a href="https://fancybeautyinstitute.org/" target="_blank">Fancy Beauty Institute</a></li>
                  <li><i className="bx bx-chevron-right"></i><a href="https://fancybeauty.com.bd/" target="_blank">Fancy Beauty E-commerce</a></li>
                  <li><i className="bx bx-chevron-right"></i><a href="http://fancybeautysuperstar.com/" target="_blank">Fancy Beauty Superstar</a></li>
                  <li><i className="bx bx-chevron-right"></i><a href="https://fancybeautyhairprofessional.com/" target="_blank">Fancy Beauty Hair Professional</a></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>Stay connected with us on our social networks to keep up with the latest updates and promotions!</p>
                <div className="social-links mt-3">
                  <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                  <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                  <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                  <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                  <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright <strong><span>Fancy Group Ltd</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/ */}
            Designed by <a href="#">Mahbub Alam Prodhan</a>
          </div>
        </div>
      </footer>{/* End Footer */}
    </div>
  );
};

export default Footer;
