import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

const HomePage = () => {
    const [slider_images, setSliderImage] = useState([]);
    const [slogans, setSlogans] = useState([]);
    const [company_details, setCompanyDatas] = useState([]);
    const [client_logos, setClientLogo] = useState([]);
    const [atGlances, setAtGlances] = useState([]);
    const [news_feed_details, setNewsFeedDatas] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchSliderImage();
        fetchSlogans();
        fetchCompanyDatas();
        fetchClientLogo();
        fetchAtGlances();
        fetchNewsFeedDatas();
        fetchProducts();
    }, []);
  
      const fetchSliderImage = async () => {
        try {
            const response = await axios.get("https://fancyltdserver.fancybeauty.com.bd/sys/api/get-slider-image");
            setSliderImage(response.data);
        } catch (error) {
            console.error("Error fetching image data:", error);
        }
      };

      const fetchSlogans = async () => {
        try {
            const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/slogans');
            setSlogans(response.data);
        } catch (error) {
            console.error('Error fetching slogans: ', error);
        }
      };

      const fetchCompanyDatas = async () => {
        try {
          const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/company-details');
          setCompanyDatas(response.data);
        } catch (error) {
          console.error('Error fetching company details: ', error);
        }
      };

      const fetchClientLogo = async () => {
            try {
                const response = await axios.get("https://fancyltdserver.fancybeauty.com.bd/sys/api/get-client-logo");
                setClientLogo(response.data);
            } catch (error) {
                console.error("Error fetching image data:", error);
            }
        };

        const fetchAtGlances = async () => {
            try {
                const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/at-glances');
                setAtGlances(response.data);
            } catch (error) {
                console.error('Error fetching at-glances: ', error);
            }
        };

        const fetchNewsFeedDatas = async () => {
            try {
              const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/newsfeed');
              setNewsFeedDatas(response.data);
            } catch (error) {
              console.error('Error fetching: ', error);
            } 
          };

        const fetchProducts = async () => {
        try {
            const response = await axios.get(`https://fancyltdserver.fancybeauty.com.bd/sys/api/product`);
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products: ', error);
        }
        };
      

  return (
    <div>

            <section id="hero" className="d-flex align-items-center bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                            
                                {company_details.map(company_detail => (
                                    <div key={company_detail.id}> {/* Adding a unique key for each mapped element */}
                                        <img src={`https://fancyltdserver.fancybeauty.com.bd/companyLogos/${company_detail.c_logo}`} className="img-fluid" alt="" style={{ width: '150px', height: '150px' }} />
                                        <h1 style={{ color: '#343a40' }}>{company_detail.c_name}</h1>
                                    </div>
                                ))}
                                
                                {slogans.map((slogan, index) => (
                                    <h2 key={index} style={{ color: '#343a40' }}>{slogan.slogan}</h2> 
                                ))}
                            
                            <div className="d-flex justify-content-center" style={{ color: '#343a40' }}>
                                <a href="#about" className="btn-get-started scrollto" style={{ color: '#343a40' }}>Get Started</a>
                                <a href="https://www.youtube.com/@fancylimited" target="_blank" className="glightbox btn-watch-video" style={{ color: '#343a40' }}><i className="bi bi-play-circle" style={{ color: '#343a40' }}></i><span>Watch Video</span></a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                            {slider_images.map((data) => (
                                <div>
                                    <img src={`https://fancyltdserver.fancybeauty.com.bd/sliderimages/${data.image}`} className="img-fluid animated" alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>


            <main id="main">

            
                <section id="clients" className="clients section-bg">
                <div className="container">

                    <div className="row" data-aos="zoom-in">
                        {client_logos.map(client_logo => (
                        <div key={client_logo.id} className="col-md-2 d-flex align-items-center justify-content-center">
                            <img src={`https://fancyltdserver.fancybeauty.com.bd/clientLogos/${client_logo.c_logo}`} className="img-fluid" alt="" />
                        </div> 
                        ))}
                    </div>

                </div>
                </section>

              
                <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                    <h2>Fancy at A Glance</h2>
                    </div>

                    <div className="row content">
                    {atGlances.map(atGlance => (   
                    <div className="col-md-12">
                        <h4>{atGlance.at_glan_title}</h4>
                        <p>{atGlance.at_glan_short_desc}</p>
                    </div>
                    ))}
                    </div>

                </div>
                </section>

            
                {/*<section id="why-us" className="why-us section-bg">
                <div className="container-fluid" data-aos="fade-up">

                    <div className="row">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content">
                        <h3>Eum ipsam laborum deleniti <strong>velit pariatur architecto aut nihil</strong></h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
                        </p>
                        </div>

                        <div className="accordion-list">
                        <ul>
                            <li>
                            <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span> Non consectetur a erat nam at lectus urna duis? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                            <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                                <p>
                                Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                                </p>
                            </div>
                            </li>

                            <li>
                            <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> Feugiat scelerisque varius morbi enim nunc? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                            <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                <p>
                                Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                </p>
                            </div>
                            </li>

                            <li>
                            <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span> Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                            <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                <p>
                                Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                                </p>
                            </div>
                            </li>

                        </ul>
                        </div>

                    </div>

                    <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{ backgroundImage: 'url("assets/img/why-us.png")' }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                    </div>

                </div>
                </section>

               
                <section id="skills" className="skills">
                <div className="container" data-aos="fade-up">

                    <div className="row">
                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                        <img src="assets/img/skills.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
                        <h3>Voluptatem dignissimos provident quasi corporis voluptates</h3>
                        <p className="fst-italic">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                        </p>

                        <div className="skills-content">

                        <div className="progress">
                            <span className="skill">HTML <i className="val">100%</i></span>
                            <div className="progress-bar-wrap">
                            <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">CSS <i className="val">90%</i></span>
                            <div className="progress-bar-wrap">
                            <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">JavaScript <i className="val">75%</i></span>
                            <div className="progress-bar-wrap">
                            <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">Photoshop <i className="val">55%</i></span>
                            <div className="progress-bar-wrap">
                            <div className="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        </div>

                    </div>
                    </div>

                </div>
                </section>*/}

				<section id="services" className="services section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                    <h2>Explore More About Fancy Products</h2>
                    <p>Dive deeper into the world of Fancy products and unlock a realm of innovation and luxury. Explore our diverse range, curated to meet your every beauty and lifestyle need with sophistication and excellence.</p>
                    </div>

                    <div className="row">
                    <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="icon-box">
                        <div className="icon"><i className="bx bxl-dribbble"></i></div>
                        <h4><a href="">Cosmetics</a></h4>
                        <p>Elevate your beauty routine with Fancy Group's premium cosmetics, featuring cutting-edge formulas and an inclusive shade range. Nurture your skin with skincare-infused products and ethical choices, while expert tutorials empower your inner artist.</p>
						<br />
                        <Link to={`/product-list-by-cosmetics-category`} className="btn btn-success m-2">More</Link>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                        <div className="icon-box">
                        <div className="icon"><i className="bx bx-file"></i></div>
                        <h4><a href="">Furnitures</a></h4>
                        <p>Transform your space with Fancy Group's exquisite furniture collection, marrying elegance with functionality for timeless style. Discover curated pieces crafted with quality materials, ensuring both beauty and durability in every design.</p>
						<br />
						<Link to={`/product-list-by-furnitures-category`} className="btn btn-success m-2">More</Link>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                        <div className="icon-box">
                        <div className="icon"><i className="bx bx-tachometer"></i></div>
                        <h4><a href="">Machineries</a></h4>
                        <p>Experience unparalleled efficiency with Fancy Group's cutting-edge machineries, designed to streamline operations and boost productivity. From advanced technology to precision engineering, our machines redefine industry standards for optimal performance.</p>
						<br />
						<Link to={`/product-list-by-machineries-category`} className="btn btn-success m-2">More</Link>
                        </div>
                    </div>

                    </div>

                </div>
                </section>
				
				
				<section id="services" class="services section-bg">
                    <div class="container" data-aos="fade-up">
                        <div class="section-title">
                            <h2>News Feed</h2>
                            <p>Stay updated with the latest in beauty innovation and community empowerment with Fancy Group's news feed. From exciting product launches to inspiring stories of entrepreneurial success, our feed keeps you informed and engaged. Discover industry insights, tips, and trends, all curated to enhance your beauty journey. Join us and stay connected to a community that knows no bounds. #BeFancy and redefine beauty, one headline at a time.</p>
                        </div>
                        <div class="row">
                        {news_feed_details.map(news_feed_detail => (
                        <div class="col-md-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div class="icon-box">
                            <div class="icon">
                                <img src={`https://fancyltdserver.fancybeauty.com.bd/newsFeedImages/${news_feed_detail.news_feed_image}`}  alt="Image" style={{ height: '200px'}} />
                            </div>
                            <h4><a href="">{news_feed_detail.news_feed_title}</a></h4>
                            <p>{news_feed_detail.news_feed_short_desc}</p>
                            </div>
                        </div>
                        ))}
                        </div>
                    </div>
                </section>
				
                
            {/*<section id="services" class="services section-bg">
              <div class="container" data-aos="fade-up">

                <div class="section-title">
                <h2>Services</h2>
                <p>At Fancy Group, we pride ourselves on offering a diverse range of premium services designed to enhance your life and elevate your experiences. From personalized beauty consultations to expert interior design advice, our services are tailored to meet your unique needs and exceed your expectations.</p>
                </div>

                <div class="row">
                <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box">
                    <div class="icon"><i class="bx bxl-dribbble"></i></div>
                    <h4><a href="">Salon Training and Support</a></h4>
                    <p>For aspiring salon owners and beauty professionals, our Beauty Academy offers comprehensive training programs and ongoing support to help you succeed in the competitive beauty industry. From mastering the latest hair styling techniques to honing your makeup skills, we're committed to empowering entrepreneurs to thrive in their businesses</p>
                    </div>
                </div>

                <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                    <div class="icon-box">
                    <div class="icon"><i class="bx bx-file"></i></div>
                    <h4><a href="">Salon Machinerize Training & Support</a></h4>
                    <p>Elevating Expertise, Empowering Success. Join our expert-led training programs designed to elevate your salon's capabilities. From mastering the latest machinery to optimizing workflow efficiency, our tailored support equips you with the skills needed to thrive in the competitive salon industry. Elevate your expertise and empower your success with Salon Machinerize Training & Support.</p>
                    </div>
                </div>

                <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                    <div class="icon-box">
                    <div class="icon"><i class="bx bx-layer"></i></div>
                    <h4><a href="">Beauty Consultations</a></h4>
                    <p>Step into our world of beauty and let our expert consultants guide you on a journey to discover the perfect skincare regimen, makeup palette, or haircare routine tailored just for you. With personalized advice and recommendations, we're here to help you look and feel your absolute best.</p>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                    <div class="icon-box">
                    <div class="icon"><i class="bx bx-tachometer"></i></div>
                    <h4><a href="">IT Consultancy</a></h4>
                    <p>Unlock the full potential of your business with our IT consultancy services. Our team of experts provides tailored solutions to streamline your operations, enhance cybersecurity, and leverage the latest technologies for sustainable growth. From strategic planning to implementation and support, trust us to guide you on your journey to digital excellence.</p>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                    <div class="icon-box">
                    <div class="icon"><i class="bx bx-layer"></i></div>
                    <h4><a href="">Customer Support</a></h4>
                    <p>Our dedication to exceptional customer service extends beyond the point of sale. Whether you have questions about our products, need assistance with an order, or simply want to share feedback, our friendly and knowledgeable support team is here to assist you every step of the way, ensuring a seamless and enjoyable Fancy experience.</p>
                    </div>
                </div>

                </div>

              </div>
            </section>*/}

                
                {/*<section id="cta" className="cta">
                <div className="container" data-aos="zoom-in">

                    <div className="row">
                    <div className="col-lg-9 text-center text-lg-start">
                        <h3>Call To Action</h3>
                        <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                    <div className="col-lg-3 cta-btn-container text-center">
                        <a className="cta-btn align-middle" href="#">Call To Action</a>
                    </div>
                    </div>

                </div>
                </section>*/}

                
              



                <section id="portfolio" className="portfolio section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                    <h2>Products</h2>
                    <p>Fancy Group offers a diverse array of premium beauty products that epitomize innovation and quality. From cutting-edge hair care solutions to luxurious skincare essentials and high-performance makeup, our product range caters to the discerning needs of beauty enthusiasts worldwide. With a commitment to excellence and customer satisfaction at the forefront, Fancy Group ensures that each product reflects our dedication to elevating beauty standards. Our extensive portfolio is designed to meet the unique preferences and rituals of individuals, empowering them to express their distinct style with confidence. At Fancy Group, we believe in sustainability, integrating eco-friendly practices into our product development process to contribute towards a greener future. Join us and explore our exceptional range of beauty products, where every item promises an extraordinary Fancy experience.</p>
                    </div>

                    {/* <ul id="portfolio-flters" className="d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                    <li data-filter="*" className="filter-active">All</li>
                    <li data-filter=".filter-app">App</li>
                    <li data-filter=".filter-card">Card</li>
                    <li data-filter=".filter-web">Web</li>
                    </ul> */}

                    <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                    

                    {products.map(product => (
                        <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                            <a href={product.product_source_link} className="details-link" title={product.product_title} target="_blank">
                                <div className="portfolio-img">
                                <img src={`https://fancyltdserver.fancybeauty.com.bd/productImages/${product.product_image}`} alt="Image" style={{ width: '150px', height: '150px'}}/>
                                </div>
                                <button className="btn btn-success m-2">Details</button>
                            </a>
                        </div>
                        ))}

                    </div>

                </div>
                </section>




               
                <section id="team" className="team">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                    <h2>Leadership Trio</h2>
                    <p>Meet the heart of Fancy Group: our dedicated team committed to delivering excellence. With passion and expertise, we strive to innovate, inspire, and exceed expectations. Together, we fuel the journey towards redefining beauty and empowering communities.</p>
                    </div>

                    <div className="row">

                    <div className="col-md-4" data-aos="zoom-in" data-aos-delay="100">
                        <div className="member d-flex align-items-start">
                        <div className="pic"><img src="assets/img/team/2648307.png" className="img-fluid" alt="" /></div>
                        <div className="member-info">
                            <h4>Khorsheda Akter</h4>
                            <span>Chairman</span>
                            <p> I lead Fancy Group with a vision for unparalleled beauty innovation and community empowerment. With a dedication to excellence, I steer our team towards pioneering solutions and fostering sustainable growth. Together, we shape the future of beauty, setting new standards of quality and inclusivity.</p>
                            <div className="social">
                            <a href=""><i className="ri-twitter-fill"></i></a>
                            <a href=""><i className="ri-facebook-fill"></i></a>
                            <a href=""><i className="ri-instagram-fill"></i></a>
                            <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4" data-aos="zoom-in" data-aos-delay="100">
                        <div className="member d-flex align-items-start">
                        <div className="pic"><img src="assets/img/team/28089f3e.jpg" className="img-fluid" alt="" /></div>
                        <div className="member-info">
                            <h4>Naymul Haque</h4>
                            <span>Managing Director</span>
                            <p> I oversee Fancy Group's strategic direction and operational efficiency. Guided by a commitment to excellence, I drive our team towards delivering exceptional products and services. Together, we navigate the dynamic landscape of the beauty industry, ensuring Fancy Group remains at the forefront of innovation and customer satisfaction.</p>
                            <div className="social">
                            <a href=""><i className="ri-twitter-fill"></i></a>
                            <a href=""><i className="ri-facebook-fill"></i></a>
                            <a href=""><i className="ri-instagram-fill"></i></a>
                            <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
					<div className="col-md-4" data-aos="zoom-in" data-aos-delay="100">
                        <div className="member d-flex align-items-start">
                        <div className="pic"><img src="assets/img/team/new-Picture.jpg" className="img-fluid" alt="" /></div>
                        <div className="member-info">
                            <h4>Oli Ahad</h4>
                            <span>Director</span>
                            <p>  I lead strategic initiatives to drive operational efficiency and maintain our commitment to excellence. We continuously innovate within the beauty industry, ensuring Fancy Group remains at the forefront of customer satisfaction and industry advancement. Together with our dedicated team, we navigate dynamic landscapes to achieve sustained success and growth. </p>
                            <div className="social">
                            <a href=""><i className="ri-twitter-fill"></i></a>
                            <a href=""><i className="ri-facebook-fill"></i></a>
                            <a href=""><i className="ri-instagram-fill"></i></a>
                            <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>


        

                    </div>

                </div>
                </section>
               
                <section id="contact" className="contact section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                    <h2>Contact</h2>
                    <p>Need assistance or have inquiries? Reach out to us effortlessly. Our dedicated team is here to provide personalized support and address your needs promptly. Connect with Fancy Group today for a seamless experience..</p>
                    </div>




                



                    <div className="row">
                    {company_details.map(company_detail => (
                    <div className="col-lg-5 d-flex align-items-stretch">
                        <div className="info">
                        <div className="address">
                            <i className="bi bi-geo-alt"></i>
                            <h4>Location:</h4>
                            <p>{company_detail.c_address}</p>
                        </div>

                        <div className="email">
                            <i className="bi bi-envelope"></i>
                            <h4>Email:</h4>
                            <p>{company_detail.c_email}</p>
                        </div>

                        <div className="phone">
                            <i className="bi bi-phone"></i>
                            <h4>Call:</h4>
                            <p>{company_detail.c_phone}</p>
                        </div>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style={{border: "0", width: "100%", height: "290px"}} allowfullscreen></iframe>
                        </div>

                    </div>
                    ))}
                    <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                        <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                        <div className="row">
                            <div className="form-group col-md-6">
                            <label for="name">Your Name</label>
                            <input type="text" name="name" className="form-control" id="name" required />
                            </div>
                            <div className="form-group col-md-6">
                            <label for="name">Your Email</label>
                            <input type="email" className="form-control" name="email" id="email" required />
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="name">Subject</label>
                            <input type="text" className="form-control" name="subject" id="subject" required />
                        </div>
                        <div className="form-group">
                            <label for="name">Message</label>
                            <textarea className="form-control" name="message" rows="10" required></textarea>
                        </div>
                        <div className="my-3">
                            <div className="loading">Loading</div>
                            <div className="error-message"></div>
                            <div className="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div className="text-center"><button type="submit">Send Message</button></div>
                        </form>
                    </div>

                    </div>

                </div>
                </section>

            </main>
    </div>
  );
};

export default HomePage;