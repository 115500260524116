import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BrandsPage = () => {
  const [brandDetails, setBrandDetails] = useState([]);
  const colors = ['rgba(127, 255, 212, 0.2)', 'rgba(255, 182, 193, 0.2)', 'rgba(25, 135, 84, 0.2)', 'rgba(240, 255, 240, 0.2)', 'rgba(250, 250, 210, 0.2)']; // Light colors with lower opacity including 
  
  useEffect(() => {
    fetchBrandDetails();
  }, []);

  const fetchBrandDetails = async () => {
    try {
      const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/brand');
      setBrandDetails(response.data);
    } catch (error) {
      console.error('Error fetching: ', error);
    }
  };

  return (
    <div>
      {brandDetails.map((brandDetail, index) => (
        <section id="brands" className="why-us section-bg" key={index} style={{backgroundColor: colors[index % colors.length]}}>
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div className="col-md-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-md-1">
                <div className="content">
                  <h1>{brandDetail.brand_title}</h1>
                  <h4 style={{color: '#36c'}}>{brandDetail.brand_short_desc}</h4>
                </div>
                <div className="accordion-list">
                  <p dangerouslySetInnerHTML={{ __html: brandDetail.brand_long_desc }}></p>
                </div>
                {/* <Link to={`/product-list-by-brand/${brandDetail.id}`} className="btn btn-success m-2">Browse More</Link> */}
              </div>
              <div className="col-md-5 mt-5 align-items-stretch order-1 order-md-2 img d-flex justify-content-center"> {/* Added justify-content-center class */}
                <br/>
                <br/>
                <br/>
                <img src={`https://fancyltdserver.fancybeauty.com.bd/brandImages/${brandDetail.brand_image}`} className="img-fluid" alt="" style={{width:'300px',height:'350px'}} />
              </div>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default BrandsPage;
