import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

const ServicePage = () => {

  const [service_details, setServiceDatas] = useState([]);

  useEffect(() => {
    fetchServiceDatas();
  }, []);

  const fetchServiceDatas = async () => {

    try {
      const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/service');
      setServiceDatas(response.data);
    } catch (error) {
      console.error('Error fetching: ', error);
    }

  };

  return (
    <div>
          <section id="services" class="services section-bg">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                <h2>Services</h2>
                <p>At Fancy Group, we pride ourselves on offering a diverse range of premium services designed to enhance your life and elevate your experiences. From personalized beauty consultations to expert interior design advice, our services are tailored to meet your unique needs and exceed your expectations.</p>
                </div>

                <div class="row">
                  {service_details.map(service_detail => (
                  <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                      <div class="icon-box">
                      <div class="icon"><i class="bx bx-layer"></i></div>
                      <h4><a href="">{service_detail.service_title}</a></h4>
                      <p>{service_detail.service_short_desc}</p>
                      </div>
                  </div>
                  ))}
                </div>

              </div>
            </section>
    </div>
  );
};

export default ServicePage;