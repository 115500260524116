import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

const NewsFeedPage = () => {

    const [news_feed_details, setDatas] = useState([]);

    useEffect(() => {
      fetchDatas();
    }, []);
  
    const fetchDatas = async () => {
      try {
        const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/newsfeed');
        setDatas(response.data);
      } catch (error) {
        console.error('Error fetching: ', error);
      } 
    };

  return (
    <div>
          <section id="services" class="services section-bg">
            <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h2>News Feed</h2>
                    <p>Stay updated with the latest in beauty innovation and community empowerment with Fancy Group's news feed. From exciting product launches to inspiring stories of entrepreneurial success, our feed keeps you informed and engaged. Discover industry insights, tips, and trends, all curated to enhance your beauty journey. Join us and stay connected to a community that knows no bounds. #BeFancy and redefine beauty, one headline at a time.</p>
                </div>
                <div class="row">
                  {news_feed_details.map(news_feed_detail => (
                  <div class="col-md-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                      <div class="icon-box">
                      <div class="icon">
                        <img src={`https://fancyltdserver.fancybeauty.com.bd/newsFeedImages/${news_feed_detail.news_feed_image}`}  alt="Image" style={{ height: '200px'}} />
                      </div>
                      <h4><a href="">{news_feed_detail.news_feed_title}</a></h4>
                      <p>{news_feed_detail.news_feed_short_desc}</p>
                      </div>
                  </div>
                  ))}
                </div>
              </div>
            </section>
    </div>
  );
};

export default NewsFeedPage;