import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
const Header = () => {

    const [company_details, setCompanyDatas] = useState([]);

    useEffect(() => {
        fetchCompanyDatas();
    }, []);

    const fetchCompanyDatas = async () => {
      try {
        const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/company-details');
        setCompanyDatas(response.data);
      } catch (error) {
        console.error('Error fetching company details: ', error);
      }
    };





  return (
    <div>
        <br />
        <br />
        <br />
        <header id="header" className="fixed-top" style={{ backgroundColor: '#198754' }}>
            <div className="container d-flex align-items-center">

            <h1 className="logo me-auto">
                {company_details.map(company_detail => (
                    <Link to="/"><img src={`https://fancyltdserver.fancybeauty.com.bd/companyLogos/${company_detail.c_logo}`} className="img-fluid" alt="" style={{ height: '500px' }} />
                    &nbsp;{company_detail.c_name}
                    </Link>
                ))}
			</h1>


            <nav id="navbar" className="navbar">
                <ul>
                <li><Link to="about" className="nav-link scrollto">This is Fancy Group</Link></li>
                <li><Link to="brands" className="nav-link scrollto">Brands</Link></li>
                <li><Link to="services" className="nav-link scrollto">Services</Link></li>
                <li className="dropdown"><a href="#"><span>Companies</span> <i className="bi bi-chevron-down"></i></a>
                    <ul>
                        <li><a href="https://fancygroupltd.com/" target="_blank">Fancy Beauty Concept</a></li>
                        <li><a href="https://planethackltd.com/" target="_blank">Fancy IT</a></li>
                        <li><a href="https://fancybeautyinstitute.org/" target="_blank">Fancy Beauty Institute</a></li>
                        <li><a href="https://fancybeauty.com.bd/" target="_blank">Fancy Beauty E-commerce</a></li>
                        <li><a href="http://fancybeautysuperstar.com/" target="_blank">Fancy Beauty Superstar</a></li>
                        <li><a href="https://fancybeautyhairprofessional.com/" target="_blank">Fancy Beauty Hair Professional</a></li>
                    </ul>
                </li>

				<li className="dropdown"><a href="#"><span>Careers</span> <i className="bi bi-chevron-down"></i></a>
                    <ul>
                        <li><a href="#" target="_blank">Why Fancy</a></li>
                        <li><a href="#" target="_blank">Locations</a></li>
                        <li><a href="#" target="_blank">Jobs & Application</a></li>
                    </ul>
                </li>
				{/* <li><Link to="team" className="nav-link scrollto">Team</Link></li> */}
				<li><Link to="news" className="nav-link scrollto">News</Link></li>
                {/*<li><Link to="contact" className="nav-link scrollto">Contact</Link></li>*/}
                {/* <li className="dropdown"><a href="#"><span className="active">Drop Down</span> <i className="bi bi-chevron-down"></i></a>
                    <ul>
                    <li><a href="#">Drop Down 1</a></li>
                    <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                        <ul>
                        <li><a href="#">Deep Drop Down 1</a></li>
                        <li><a href="#">Deep Drop Down 2</a></li>
                        <li><a href="#">Deep Drop Down 3</a></li>
                        <li><a href="#">Deep Drop Down 4</a></li>
                        <li><a href="#">Deep Drop Down 5</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Drop Down 2</a></li>
                    <li><a href="#">Drop Down 3</a></li>
                    <li><a href="#">Drop Down 4</a></li>
                    </ul>
                </li> */}
                {/*<li><a className="getstarted scrollto" href="#about"><span>Get Started</span></a></li>*/}
                </ul>
                <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>

            </div>
        </header>
    </div>
  );
};

export default Header;