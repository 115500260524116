import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams hook

const ProductListPage = () => {

    const [products, setDatas] = useState([]);

    useEffect(() => {
      fetchDatas();
    }, []);
  
    const fetchDatas = async () => {
      try {
        const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/machineries-products');
        setDatas(response.data);
      } catch (error) {
        console.error('Error fetching: ', error);
      } 
    };

  return (
    <div>

      <section id="portfolio" className="portfolio section-bg">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>Machineries Gallery</h2>
              <p>
                Fancy Group offers a diverse array of premium beauty products that epitomize innovation and quality. From cutting-edge hair care solutions to luxurious skincare essentials and high-performance makeup, our product range caters to the discerning needs of beauty enthusiasts worldwide. With a commitment to excellence and customer satisfaction at the forefront, Fancy Group ensures that each product reflects our dedication to elevating beauty standards. Our extensive portfolio is designed to meet the unique preferences and rituals of individuals, empowering them to express their distinct style with confidence. At Fancy Group, we believe in sustainability, integrating eco-friendly practices into our product development process to contribute towards a greener future. Join us and explore our exceptional range of beauty products, where every item promises an extraordinary Fancy experience.
              </p>
            </div>
            
            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
              {products.map(product => (
              <div  key={product.id} className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <a href={product.product_source_link} className="details-link" title={product.product_title} target="_blank">
                    <div className="portfolio-img">
                      <img src={`https://fancyltdserver.fancybeauty.com.bd/productImages/${product.product_image}`} alt="Image" style={{ width: '150px', height: '150px'}}/>
                    </div>
                    <button className="btn btn-success m-2">Details</button>
                  </a>
              </div>
              ))}
            </div>

        </div>
      </section>

    </div>
  );
};

export default ProductListPage;
