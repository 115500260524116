import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

const AboutPage = () => {

  const [group_details, setGroupDatas] = useState([]);

  useEffect(() => {
    fetchGroupDatas();
  }, []);

  const fetchGroupDatas = async () => {
    try {
      const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/group');
      setGroupDatas(response.data);
    } catch (error) {
      console.error('Error fetching: ', error);
    }
  };

  return (
    <div>
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                <h2>About Us</h2>
                </div>

                <div className="row content">
                <div className="col-md-12">
                  {group_details.map(group_detail => (
                    <div key={group_detail.group_title}>
                      <h3>{group_detail.group_title}</h3>
                      <h6 style={{color: '#36c'}}>{group_detail.group_short_desc}</h6>
                      <p dangerouslySetInnerHTML={{ __html: group_detail.group_long_desc }}></p>
                    </div>
                  ))}

                    {/* <ul>
                    <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
                    <li><i className="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit</li>
                    <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
                    </ul> */}
                </div>
                {/* <div className="col-lg-6 pt-4 pt-lg-0">
                    <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <a href="#" className="btn-learn-more">Learn More</a>
                </div> */}
                </div>

            </div>
          </section>
    </div>
  );
};

export default AboutPage;