import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './layouts/Header';
import Footer from './layouts/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import TeamPage from './pages/TeamPage';
import BrandsPage from './pages/BrandsPage';
import ServicePage from './pages/ServicePage';
import AdminLoginPage from './pages/AdminLoginPage';
import ProductListPage from './pages/ProductListPage';
import NewsFeedPage from './pages/NewsFeedPage';
import ProductByCosmeticsPage from './pages/ProductByCosmeticsPage';
import ProductByFurnituresPage from './pages/ProductByFurnituresPage';
import ProductMachineriesPage from './pages/ProductMachineriesPage';


function App() {
  return (
    
    <div className="App">

        <Header />
        <Routes>

          <Route path="/" element={ <HomePage /> } />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/brands" element={<BrandsPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/news" element={<NewsFeedPage />} />
          <Route path="/login" element={<AdminLoginPage />} />
          <Route path="/product-list-by-brand/:id" element={<ProductListPage />} />
          <Route path="/product-list-by-cosmetics-category" element={<ProductByCosmeticsPage />} />
          <Route path="/product-list-by-furnitures-category" element={<ProductByFurnituresPage />} />
          <Route path="/product-list-by-machineries-category" element={<ProductMachineriesPage />} />

        </Routes>
        <ToastContainer />
        <Footer />
        
    </div>
  );
}

export default App;
