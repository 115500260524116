import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminLoginPage = () => {

  const [formData, setFormData] = useState({ email: '', password: '' });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };
  

const handleSubmit = async e => {
    e.preventDefault();
    try {
        const response = await axios.post('https://fancyltdserver.fancybeauty.com.bd/sys/api/logincheck', formData);
        setFormData({ email: '', password: '' });
        console.log(response.data.message);
        toast.success(response.data.message);
        //window.location.href = 'http://localhost:3000/admin/dashboard';
        window.open('http://fancyltdadmin.fancybeauty.com.bd/admin/dashboard', '_blank');
    } catch (error) {
        console.error('Error creating or updating: ', error);
        toast.error('Invalid Credentials');
    }
};

  return (
    <div>
          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                <h2>Login</h2>
                {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
                </div>

                <div className="row">
                    <div className="col-md-12 d-flex align-items-stretch">
                        <form onSubmit={handleSubmit} className="php-email-form">
                        <div className="form-group">
                            <label for="name">Email</label>
                            <input type="email" className="form-control" name="email" id="email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label for="name">Password</label>
                            <input type="password" className="form-control" name="password" id="password" value={formData.password} onChange={handleChange} required />
                        </div>
                        <div className="text-center"><button type="submit">Login</button></div>
                        </form>
                    </div>
                </div>

            </div>
            </section>
    </div>
  );
};

export default AdminLoginPage;